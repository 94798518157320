/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { at } from 'lodash';
import { useField } from 'formik';
import './multipleSelect.scss';
import { MISC_VALUES } from '../../utils/constants/constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

function MultipleSelect(props) {
  const classes = useStyles();
  const { label, isRequired, data, displayField, isDisabled, isFromCreateNewUser, hasDefaultValue, _className, ...rest } = props;
  const [field, meta] = useField(props);
  const [selectAll, setSelectAll] = useState(false);
  let { value: selectedValue } = field;

  if (!selectedValue) {
    if (hasDefaultValue) {
      selectedValue = [{ accountId: "select" }];
    } else {
      selectedValue = [];
    }
  } else if (hasDefaultValue) {
    if (selectedValue.length > 0 && selectedValue[0].accountId === 'select') {
      selectedValue.shift();
    }
  }

  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  useEffect(() => {
    setSelectAll(selectedValue.length === data.length);
  }, [selectedValue, data.length]);

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    if (checked) {
      const allValues = data.map((option) => option.accountId);
      field.onChange({ target: { name: field.name, value: allValues } });
    } else {
      field.onChange({ target: { name: field.name, value: [] } });
    }
  };


  const handleChange = (event) => {
    const { value } = event.target;
    if (value.includes('selectAll')) {
      const allValues = data.map((option) => option.accountId);
      setSelectAll(true);
      field.onChange({ target: { name: field.name, value: allValues } });
    } else {
      setSelectAll(value.length === data.length);
      field.onChange({ target: { name: field.name, value: value.filter(v => v !== 'selectAll') } });
    }
  };

  const handleSelectAllClick = () => {
    const event = { target: { checked: !selectAll } };
    setTimeout(() => {
      handleSelectAll(event);
    }, 10)
  };

  const renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  return (
    <Grid container className="multiple-select-container">
      <FormControl className="multiple-select">
        <FormLabel className="labelSelect">
          {isRequired
            ? (
              <label className="labelSelect">
                {label} <span className="span-required">*</span>
              </label>
            )
            : (
              <label className="labelSelect">{label}</label>
            )}
        </FormLabel>
        <Select
          disabled={isDisabled === true ? isDisabled : false}
          {...field}
          labelId="mutiple-select-label"
          multiple
          value={selectedValue ? selectedValue : ''}
          onChange={handleChange}
          renderValue={
            (isDisabled === true && isFromCreateNewUser === false)
              ? (selected) => 'please select 1 tab'
              : (selected) => {
                if (selected[0].accountId === MISC_VALUES.SELECT) {
                  return 'Select';
                }
                if (selected.length > 1) {
                  return `${selected.length} ${MISC_VALUES.ACCOUNTS_SELECTED}`;
                }
                return `${selected.length} ${MISC_VALUES.ACCOUNT_SELECTED}`;
              }
          }
          MenuProps={MenuProps}
          disableUnderline
        >
          <MenuItem className={_className} key="selectAll" value="selectAll">
            <ListItemIcon>
              <Checkbox
                id="select-all-checkbox"
                checked={selectAll}
                indeterminate={selectedValue.length > 0 && selectedValue.length < data.length}
                onChange={handleSelectAll}
              />
            </ListItemIcon>
            <span
              onClick={handleSelectAllClick}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <ListItemText primary="Select All" />
            </span>
          </MenuItem>
          {data.map((option) => (
            <MenuItem className={_className} key={option.accountId} value={option.accountId}>
              <ListItemIcon>
                <Checkbox checked={selectedValue.indexOf(option.accountId) > -1} />
              </ListItemIcon>
              <ListItemText primary={`${option[displayField]}-${option['accountName']}`} />
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginTop: '-10px' }}>{renderHelperText()}</div>
      </FormControl>
    </Grid>
  );
}

export default MultipleSelect;